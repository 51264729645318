import React, {useEffect, useState} from "react"
import LayoutLogout from '../features/_layout/LayoutLogout'
import Auth from "../features/auth/"
import { hasAuthToken } from '../actions/_auth'

export default () => {
  const [loading, setLoading] = useState(true)
  const [logged_in, setLoggedIn] = useState(false)
  useEffect(() => {
    if(hasAuthToken() === true) {
      setLoggedIn(true)
      window.location = "/app/all"
    } else {
      setLoggedIn(false)
    }
    setLoading(false)
  }, [])

  if(loading === true) {
    return <span>loading...</span>
  } else if(logged_in === false) {
    return(
      <LayoutLogout>
        <Auth />
      </LayoutLogout>
    )
  } else {
    return <span>Redirecting...</span>
  }
}
